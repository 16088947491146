import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES_NAME } from "./routers/routeConstants";
import { Home } from "./views/home";
import AboutUs from "./views/about";
import { Navbar } from "./layout/navbar";
import { Product } from "./views/product";
import DigilockerIntegrationServices from "./component/digilockerIntegration";
import { Ekyc } from "./component/ekyc";
import { OnlineCalculator } from "./component/onlineCalculator"
import Crm from "./component/crm"
import WebsiteDesignServices from "./component/websiteDesignServices";
import TermsAndCondition from "./component/termsAndCondition";
import RefundPolicy from "./component/refundPolicy";
import ContactUs from "./component/contactUs";
import PrivacyPolicy from "./component/privacyPolicy";
import SocialLink from "./component/socialLink";
import Chatbot from "./component/chatbot"
import Digital_E_Sign from "./component/Digital-E-Sign";
import Sgb from "./component/sbg";
import Re_Kyc from "./component/rekyc";
import { Footer } from "./layout/footer";
import RmLogin from "./component/rm-login";
import { Request } from "./component/requestDemo";
import RequestDemo from "./component/requestDemoPage/page";
function App() {

  return (
    <>
      <Navbar />
      <SocialLink/>
      <Routes>
        <Route path={ROUTES_NAME.HOME} element={<Home />} />
        <Route path={ROUTES_NAME.ABOUT} element={<AboutUs />} />
        <Route path={ROUTES_NAME.EKYC} element={<Ekyc/>} />
        <Route path={ROUTES_NAME.CRM} element={<Crm/>} />
        <Route path={ROUTES_NAME.WEBSITEDESIGNSERVICES} element={<WebsiteDesignServices/>} />
        <Route path={ROUTES_NAME.ONLINECALCULATOR} element={<OnlineCalculator/>} />
        <Route path={ROUTES_NAME.DIGILOCKERINTEGRATIONSERVICE} element={<DigilockerIntegrationServices/>} />
        <Route path={ROUTES_NAME.PRIVACYPOLICY} element={<PrivacyPolicy/>} />
        <Route path={ROUTES_NAME.COUNTACTUS} element={<ContactUs/>} />
        <Route path={ROUTES_NAME.REFUNDPOLICY} element={<RefundPolicy/>} />
        <Route path={ROUTES_NAME.TERMSANDCONDITION} element={<TermsAndCondition/>} />
        <Route path={ROUTES_NAME.CHATBOT} element={<Chatbot/>} />
        <Route path={ROUTES_NAME.DIGITAL_E_SIGN} element={<Digital_E_Sign/>} />
        <Route path={ROUTES_NAME.SGB} element={<Sgb/>} />
        <Route path={ROUTES_NAME.REKYC} element={<Re_Kyc/>} />
        <Route path={ROUTES_NAME.RM_LOGIN} element={<RmLogin/>} />
        <Route path={ROUTES_NAME.REQUEST_DEMO} element={<RequestDemo/>} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
