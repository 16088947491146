import React, { useState } from "react";
import emailjs from "emailjs-com";

const RequestDemo = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    company_name: "",
    mobile_number: "",
    company_email: "",
    product: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .send(
        "service_b5tbvnb", // Your EmailJS Service ID
        "template_6gfiifc", // Your EmailJS Template ID
        formData,
        "IekRfx3l4RdUenHKA" // Your EmailJS User ID
      )
      .then(
        (response) => {
          alert("Message sent successfully!");
          setIsSubmitting(false);
          setFormData({
            full_name: "",
            company_name: "",
            mobile_number: "",
            company_email: "",
            product: "",
          });
        },
        (error) => {
          alert("Failed to send the message. Please try again.");
          console.error("EmailJS Error:", error);
          setIsSubmitting(false);
        }
      );
  };

  return (
    <div className="bg-blue-100 min-h-screen flex flex-col justify-between pt-16">
      <div className="flex flex-col lg:flex-row items-center justify-center p-8 bg-blue-600 text-black">
        <div className="lg:w-1/2 w-full px-6">
          <h1 className="text-3xl font-bold mb-4">
            Empowered Your Business With Finovo.Tech..
          </h1>
          <p className="mb-6">
            In this demo, you can experience how Finovo tailormade Artificial
            Intelligence Products & Services help your business.
          </p>
          <div className="mb-4">
            <h2 className="font-semibold text-lg">➤ Go Paperless & Avail Our Automation Software Solutions.</h2>
            <p>
              Leverage advanced technology for your business, whether you need
              bulk Aadhaar e-Sign, E-KYC Software, Automated Email Software,
              Whatsapp Chatbot, and more!
            </p>
          </div>
          <div>
            <h2 className="font-semibold text-lg">➤ Boost Your Sales With Cutting-Edge Analytics.</h2>
            <p>
              Gear up your business operations with deep insights into
              customers' behaviour and purchasing patterns, and maximize your
              ROI.
            </p>
          </div>
        </div>

        <div className="lg:w-1/3 w-full bg-white rounded-lg shadow-lg p-6 border-1">
          <form onSubmit={handleSubmit}>
            <h2 className="text-xl font-bold mb-4">Contact Us</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Full Name</label>
              <input
                type="text"
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}
                placeholder="Your Name"
                className="w-full border rounded px-3 py-2"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Your Company Name</label>
              <input
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                placeholder="Company Name"
                className="w-full border rounded px-3 py-2"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Mobile Number</label>
              <input
                type="tel"
                name="mobile_number"
                value={formData.mobile_number}
                onChange={handleChange}
                placeholder="+91 Enter Mobile Number"
                className="w-full border rounded px-3 py-2"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Company Email</label>
              <input
                type="email"
                name="company_email"
                value={formData.company_email}
                onChange={handleChange}
                placeholder="Your Company Email"
                className="w-full border rounded px-3 py-2"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Select Product</label>
              <select
                name="product"
                value={formData.product}
                onChange={handleChange}
                className="w-full border rounded px-3 py-2"
                required
              >
                <option value="">Choose a Product</option>
                <option value="kyc-api">KYC API</option>
                <option value="ekyc">E-KYC</option>
                <option value="app-development">App Development</option>
                <option value="web-development">Web Development</option>
                <option value="co-location-services">Co-location Services</option>
                <option value="integrated-client-dashboard">Integrated Client Dashboard</option>
                <option value="digilocker-integration">DigiLocker Integration</option>
                <option value="chatbot-support">Chatbot Support</option>
                <option value="e-ipo-solutions">E-IPO Solutions (NSE/BSE)</option>
                <option value="rekyc-account-closure">Re-KYC / Account Closure Management</option>
              </select>
            </div>
            <div className="text-sm text-gray-600 mb-4">
              By registering, you confirm that you agree to the storing and
              processing of your personal data by Finovo Tech as
              described in the <a href="#" className="text-blue-600 underline">Privacy Statement</a>.
            </div>
            <button
              type="submit"
              className="w-full border-1 bg-blue  text-white py-2 rounded"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "SUBMIT"}
            </button>
          </form>
        </div>
      </div>

   
      {/* <footer className="bg-white text-center py-4">
        <div className="text-gray-600 text-sm">
          For Sales: <a href="mailto:sales@meon.co.in" className="text-blue-600">sales@meon.co.in</a> | For Career:{" "}
          <a href="mailto:hr@meon.co.in" className="text-blue-600">hr@meon.co.in</a>
        </div>
        <div className="flex justify-center mt-4 space-x-4">
          <img src="/images/findoc.png" alt="Findoc" className="h-8" />
          <img src="/images/mandot.png" alt="Mandot" className="h-8" />
          <img src="/images/marwadi.png" alt="Marwadi" className="h-8" />
          <img src="/images/mindex.png" alt="Mindex" className="h-8" />
          <img src="/images/parasram.png" alt="Parasram" className="h-8" />
          <img src="/images/prithvi.png" alt="Prithvi" className="h-8" />
        </div>
      </footer> */}


    </div>
  );
};

export default RequestDemo;
